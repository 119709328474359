import React from "react";
import { Route, Routes } from "react-router-dom";
import Success from "./Success";

const PathSuccess = () => {
  return (
    <Routes>
      <Route
        path="/success"
        element={
          <>
            <Success />
          </>
        }
      />
    </Routes>
  );
};

export default PathSuccess;
