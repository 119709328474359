import React, { } from 'react'
import { Helmet } from 'react-helmet'
// import Header from './components/Header';
// import Section from './components/Section'
// import Home from './components/Home';
// import Footer from './components/Footer';
import All from './components/All';
import PathSuccess from './components/pathSuccess';
import PathKuisioner from './components/pathKuisioner';
import { BrowserRouter as Router } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="App">
        <All/>
        <PathSuccess/>
        <PathKuisioner/>
      </div>
    </Router>
  );
}

export default App;
