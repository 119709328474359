import React from "react";

function Section() {
  return (
    <>
      <section id="hero">
        <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
          <h1 class="mb-4 pb-0">
            Seminar Nasional
            <br />
            <span>KOMA</span> 2024
          </h1>
          <p class="mb-4 pb-0">Cooming Soon</p>
          <a
            href="https://www.youtube.com/watch?v=p1PSSe3KPfU"
            class="glightbox play-btn mb-4"
          ></a>
          <a href="#buy-tickets" class="about-btn scrollto">
            Register Now
          </a>
          <a href="#about" class="about-btn scrollto">
            About SEMNAS
          </a>
        </div>
      </section>
    </>
  );
}

export default Section;
