import React from "react";
import { Link } from "react-router-dom";

const Respon = () => {
  return (
    <main className="main-page">
      {/* ======= Speaker Details Sectionn ======= */}
      <section>
        <div className="container">
          <div className="section-header text-center">
            {" "}
            {/* Tambahkan kelas text-center di sini */}
            <h2>Selamat Pendaftaran Berhasil</h2>
            {/* <p>Praesentium ut qui possimus sapiente nulla.</p> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <img
                src="assets/img/success.png"
                alt="Sukses"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div className="col-md-12">
              <div className="details text-center">
                <h2>
                  Terimakasih telah mencoba website ini, mohon untuk mengisi
                  kuisioner dengan mengklik tombol kuisioner di bawah ini
                </h2>
                <Link to="https://docs.google.com/forms/d/e/1FAIpQLScw4UF_Dtu9x5SMHzI74cc8pdbVMhrstXPeC8CYfs2RrtlAUA/viewform?usp=sf_link" className="btn btn-success">
                  Kuisioner
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Respon;
