import React from "react";

function Header() {
  return (
    <header id="header" className="d-flex align-items-center ">
      <div className="container-fluid container-xxl d-flex align-items-center">
        <div id="logo" className="me-auto">
          {/* <h1>
            <a href="http://localhost:3005">
              Semnas<span>Koma</span>
            </a>
          </h1> */}
          <a href="/" className="scrollto">
            <img src="assets/img/koma.png" alt="" title="" />
          </a>
        </div>
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <a
                className="nav-link scrollto active"
                href="https://vandr.online/#hero"
              >
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#speakers">
                Speakers
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#gallery">
                Gallery
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#supporters">
                Sponsors
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#faq">
                FAQ
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" />
        </nav>
        <a className="buy-tickets scrollto" href="#buy-tickets">
          Register Now
        </a>
      </div>
    </header>
  );
}

export default Header;
