import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Home() {
  const [faqs, setFaqs] = useState([]);
  const [pembicaras, setPembicaras] = useState([]);
  const [gallerys, setGallerys] = useState([]);

  const [nama, setNama] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [status, setStatus] = useState("");
  const [noIdentitas, setNoIdentitas] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getPembicaras();
    getGallerys();
    getFaqs();
  }, []);

  const getPembicaras = async () => {
    const response = await axios.get("https://api.vandr.online/pembicaras");
    setPembicaras(response.data);
  };

  const getGallerys = async () => {
    const response = await axios.get("https://api.vandr.online/gallerys");
    setGallerys(response.data);
  };

  const getFaqs = async () => {
    const response = await axios.get("https://api.vandr.online/faqs");
    setFaqs(response.data);
  };

  const daftar = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://api.vandr.online/pesertas", {
        nama: nama,
        jenis_kelamin: jenisKelamin,
        status: status,
        no_identitas: noIdentitas,
        email: email,
        whatsapp: whatsapp,
        alamat: alamat,
      });
      navigate("/kuisioner");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
    return false;
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);

    // Set default value for noIdentitas based on selected status
    if (selectedStatus === "umum") {
      setNoIdentitas("umum");
    } else {
      setNoIdentitas(""); // Clear noIdentitas if status is not 'umum'
    }
  };
  return (
    <main id="main">
      <section id="about">
        <div className="container position-relative" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6">
              <h2>About SEMNAS</h2>
              <p>
                Seminar Nasional KOMA merupakan acara seminar tahunan yang
                selalu di adakan oleh KOMA dengan tema yang sangat menarik dan
                pastinya related dengan zaman dan trend yang berlangsung, jangan
                lewatkan acara sebaik ini jadi segera daftarkan dirimu sekarang
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Where</h3>
              <p>Coming Soon</p>
            </div>
            <div className="col-lg-3">
              <h3>When</h3>
              <ul>
                <li>
                  <p>Pendaftaran : Coming Soon</p>
                </li>
                <li>
                  <p>Main event : Coming Soon</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="speakers">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Event Speakers</h2>
            <p>Here are some of our speakers</p>
          </div>

          <div className="row justify-content-center">
            {pembicaras.map((pembicara) => (
              <div key={pembicara.id} className="col-lg-4 col-md-6">
                <div
                  className="speaker"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img
                    src={pembicara.url}
                    alt={pembicara.nama}
                    className="img-fluid"
                  />
                  <div className="details">
                    <h3>
                      <a href="#speakers">{pembicara.nama}</a>
                    </h3>
                    <p>{pembicara.deskripsi}</p>
                    <div className="social"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="gallery">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Gallery</h2>
            <p>Check our gallery from the recent events</p>
          </div>
        </div>

        <div className="gallery-slider swiper">
          <div className="swiper-wrapper align-items-center">
            {gallerys.map((gallery) => (
              <div key={gallery.id} className="swiper-slide">
                <a className="gallery-lightbox">
                  <img
                    src={gallery.url}
                    className="img-fluid"
                    alt={gallery.alt}
                  />
                </a>
              </div>
            ))}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </section>

      <section id="supporters" className="section-with-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Sponsors and Supports</h2>
          </div>

          <div
            className="row no-gutters supporters-wrap clearfix"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="supporter-logo">
                <img
                  src="assets/img/supporters/sponsor.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="supporter-logo">
                <img
                  src="assets/img/supporters/sponsor.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="supporter-logo">
                <img
                  src="assets/img/supporters/support.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="supporter-logo">
                <img
                  src="assets/img/supporters/support.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>F.A.Q</h2>
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="col-lg-9">
              <ul className="faq-list">
                {faqs.map((faq, index) => (
                  <li key={faq.uuid}>
                    <div
                      data-bs-toggle="collapse"
                      href={`#${faq.uuid}`}
                      className="collapsed question"
                    >
                      {faq.pertanyaan}
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id={faq.uuid}
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>{faq.jawaban}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="buy-tickets" className="section-with-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>REGiSTER</h2>
            <p>register now and get the benefits</p>
          </div>

          <div className="row">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    Umum
                  </h5>
                  <h6 className="card-price text-center">Rp. 100.000</h6>
                  <hr />
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#buy-ticket-modal"
                      data-ticket-type="umum"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    Mahasiswa AMIKOM
                  </h5>
                  <h6 className="card-price text-center">Rp. 80.000</h6>
                  <hr />
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#buy-ticket-modal"
                      data-ticket-type="amikom"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    Anggota Koma
                  </h5>
                  <h6 className="card-price text-center">Rp. 50.000</h6>
                  <hr />
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#buy-ticket-modal"
                      data-ticket-type="koma"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="buy-ticket-modal" className="modal fade">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Daftar</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={daftar}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="form-group">
                    <label htmlFor="nama">Nama</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nama"
                      placeholder="Masukan Nama"
                      value={nama}
                      onChange={(e) => setNama(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="jenisKelamin">Jenis Kelamin</label>
                    <select
                      id="jenisKelamin"
                      name="jenisKelamin"
                      className="form-select"
                      value={jenisKelamin}
                      onChange={(e) => setJenisKelamin(e.target.value)}
                    >
                      <option value="">-- Jenis Kelamin --</option>
                      <option value="laki">Laki-laki</option>
                      <option value="perempuan">Perempuan</option>
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="status">Status</label>
                    <select
                      id="ticket-type"
                      name="ticket-type"
                      className="form-select"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">-- Pilih Status --</option>
                      <option value="umum">Umum</option>
                      <option value="amikom">Mahasiswa AMIKOM</option>
                      <option value="koma">Anggota KOMA</option>
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="noIdentitas">No Identitas</label>
                    <input
                      type="text"
                      className="form-control"
                      name="noIdentitas"
                      placeholder="Masukan NIM atau No Anggota KOMA"
                      value={noIdentitas}
                      onChange={(e) => setNoIdentitas(e.target.value)}
                      disabled={status === "umum"} // Disable input if status is 'umum'
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Masukan Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="whatsapp">WhatsApp</label>
                    <input
                      type="text"
                      className="form-control"
                      name="whatsapp"
                      placeholder="Masukan WhatsApp"
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="alamat">Alamat</label>
                    <input
                      type="text"
                      className="form-control"
                      name="alamat"
                      placeholder="Masukan Alamat"
                      value={alamat}
                      onChange={(e) => setAlamat(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
