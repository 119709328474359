import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={4} md={6} className="footer-info">
              {/* <h1>
                <a href="index.html">
                  <span>KOMA</span>
                </a>
              </h1> */}
              <img src="assets/img/koma.png" alt="TheEvenet" />
              <p>
                KOMA Adalah Komunitas yang bergerak dalam bidang multimedia di Universitas Amikom Yogyakarta
              </p>
            </Col>
            <Col lg={4} md={6} className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right" /> <a href="#hero">Home</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#about">About Us</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#speakers">Speakers</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#gallery">Gallery</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#supporters">Sponsors</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" /> <a href="#faq">FAQ</a>
                </li>
              </ul>
            </Col>
            <Col lg={4} md={6} className="footer-contact">
              <h4>Hubungi Kami</h4>
              <p>
                Gedung BSC Lt. 3 Ruang VI.3.6
                <br />
                Kampus Terpadu Universitas AMIKOM Yogyakarta
                <br />
                Jl. Ringroad Utara Condong Catur Yogyakarta. <br />
                <strong>Whatsapp Chako:</strong> 08********
                <br />
                <strong>Email:</strong> info@example.com
                <br />
              </p>
              <div className="social-links">
                <a href="https://twitter.com" className="twitter">
                  <i className="bi bi-twitter" />
                </a>
                <a href="https://facebook.com" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://instagram.com" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="https://linkedin.com" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
                <a href="https://tiktok.com" className="tiktok">
                  <i className="bi bi-tiktok" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong>Seminar Nasional KOMA</strong>. All Rights
          Reserved
        </div>
      </div>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </footer>
  );
}

export default Footer;
